import { Root, SwitchProps, Thumb } from '@radix-ui/react-switch';
import { forwardRef } from 'react';
import './styles.css';

export const Switch = forwardRef<HTMLInputElement, SwitchProps>((props, ref) => {
  return (
    <Root className="h-5 min-w-10 w-10 px-1 flex justify-start items-center rounded-full bg-switch" {...props}>
      <Thumb className="block w-3.5 h-3.5 bg-white bg-opacity-20 rounded-full transition-all duration-200 data-[state=checked]:bg-opacity-100 data-[state=checked]:translate-x-[1.125rem]" />
    </Root>
  );
});
