import { SVGAttributes } from "react";

export function Close(props: SVGAttributes<SVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="4 4 16 16"
      fill="none"
      {...props}
    >
      <path
        d="M17.625 6.375L6.375 17.625"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.375 6.375L17.625 17.625"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
