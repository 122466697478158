import { ButtonHTMLAttributes, forwardRef } from 'react';
import './styles.css';
import clsx from 'clsx';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  extraClass?: string;
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, extraClass, ...props }, ref) => {
    return (
      <button type='button' className={clsx('btn', extraClass)} ref={ref} {...props}>
        {children}
      </button>
    );
  }
);
