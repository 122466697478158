import { DropdownIcon } from 'assets/icons/DropdownIcon';
import { WalletDisconnectDropdown } from 'components/DisconnectWalletDropdown';
import { useConnectedIcon } from 'hooks/useConnectedIcon';
import { formatAddress } from 'utils/wallet';
import { useAccount } from 'wagmi';

export function WalletMenu() {
  const { address } = useAccount();
  const icon = useConnectedIcon();

  return (
    <>
      <WalletDisconnectDropdown>
        <button className="flex items-center gap-2 p-[0.6875rem] rounded-lg md:px-5 md:py-[0.9375rem] md:rounded-full bg-white bg-opacity-[.06] font-bold text-sm leading-5 border-gradient">
          <img src={icon} className="w-5 min-w-5 h-5" alt="" />
          <span className='hidden md:flex items-center gap-2'>
            {address && formatAddress(address)}
            <DropdownIcon />
          </span>
        </button>
      </WalletDisconnectDropdown>
    </>
  );
}
