import { darkTheme, RainbowKitProvider } from '@rainbow-me/rainbowkit';
import { AuthController } from 'components/Auth';
import { PrivateRoute } from 'components/PrivateRoute';
import { stage } from 'configs/api';
import { wagmiConfig } from 'configs/wagmi';
import { useEffect } from 'react';
import {
  createBrowserRouter,
  createHashRouter,
  RouterProvider,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { WagmiConfig } from 'wagmi';
import { MainView } from './screens/Main';
import { SingIn } from './screens/SignIn';
import '@rainbow-me/rainbowkit/styles.css';

const routes = [
  {
    path: '/',
    element: <AuthController />,
    children: [
      {
        path: '/',
        element: (
          <PrivateRoute>
            <MainView />
          </PrivateRoute>
        ),
      },
      {
        path: '/sign-in',
        element: <SingIn />,
      },
    ],
  },
];

const routers = {
  development: createHashRouter,
  staging: createBrowserRouter,
  production: createBrowserRouter,
};

const router = routers[stage](routes, {
  basename: '/apanel',
});

function App() {
  useEffect(() => {
    if (window.location.pathname === '/') {
      if (stage === 'production') {
        window.open('https://retrobridge.io/', '_self');
      } else if (stage === 'staging') {
        window.open('/apanel', '_self');
      } else if (stage === 'development' && window.location.hash === '') {
        window.open('/#/apanel', '_self');
      }
    }
  }, []);

  return (
    <WagmiConfig config={wagmiConfig}>
      <RainbowKitProvider chains={[]} theme={darkTheme()} locale="en">
        <ToastContainer className="toast-position" />
        <RouterProvider router={router} />
      </RainbowKitProvider>
    </WagmiConfig>
  );
}

export default App;
