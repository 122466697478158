import { Button } from 'components/Button';
import ConnectModal from 'components/ConnectWalletDialog';
import { Header } from 'components/Header';
import { Loader } from 'components/Loader';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { authAPI } from 'services/auth';
import { useAccount, useDisconnect } from 'wagmi';

export function SingIn() {
  const { isConnected, address } = useAccount();
  const { disconnect } = useDisconnect();
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const checkIsAuthWallet = async (address: string) => {
    try {
      await authAPI.checkWallet(address);
      return true;
    } catch (error) {
      return false;
    }
  };

  const oldEthAddressRef = useRef<`0x${string}` | null>(null);

  useEffect(() => {
    if (!address) {
      setIsLoading(false);
      return;
    }

    if (address === oldEthAddressRef.current) {
      setIsLoading(false);
      return;
    }

    setIsLoading(true);
    checkIsAuthWallet(address)
      .then((isAuth) => {
        if (isAuth) {
          navigate('/');
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [address]);

  if (isLoading) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  return (
    <>
      <Header />
      <main className="min-h-main flex flex-col justify-center items-center">
        {!isConnected && (
          <ConnectModal>
            <Button disabled={isConnected} extraClass="w-[13.125rem]">
              Connect Wallet
            </Button>
          </ConnectModal>
        )}
        {isConnected && (
          <Button extraClass="w-[13.125rem]" onClick={() => disconnect()}>
            Disconnect
          </Button>
        )}
      </main>
    </>
  );
}
