import axios, { AxiosInstance } from 'axios';
import { BASE_URL, stage } from 'configs/api';
import { AppTypedData, ISimpleApiResp } from 'types';
import { NetworkTypes } from 'types/networks';
import { Signature } from 'viem';

class AuthApi {
  private _api: AxiosInstance;

  constructor() {
    this._api = axios.create({
      baseURL: BASE_URL,
      withCredentials: true,
    });
  }

  async getSignMessage() {
    const url = `/api/wallet_auth/message`;
    try {
      const { data } = await this._api.get<ISimpleApiResp<AppTypedData>>(url);

      return data;
    } catch (error) {
      stage === 'development' &&
        console.warn(error, '======error getSignMessage');
      throw error;
    }
  }

  async validateSignedMessage(
    wallet_address: string,
    signature: string | Signature
  ) {
    const url = `/api/wallet_auth/message`;
    try {
      const isAdmin = await this.isAdminWallet(wallet_address);

      if (!isAdmin) {
        throw new Error('Not an admin wallet');
      }

      await this._api.post(url, {
        wallet_address,
        network_type: NetworkTypes.EVM,
        signature,
      });
    } catch (error) {
      stage === 'development' &&
        console.warn(error, '======error validateSignedMessage');
      throw error;
    }
  }

  async checkWallet(wallet_address: string) {
    const url = `/api/wallet_auth/wallet/${wallet_address}`;
    try {
      await this._api.get(url, {
        headers: { 'network-type': NetworkTypes.EVM },
      });
      const isAdmin = await this.isAdminWallet(wallet_address);

      if (!isAdmin) {
        throw new Error('Not an admin wallet');
      }

    } catch (error) {
      stage === 'development' && console.warn(error, '======error checkWallet');
      throw error;
    }
  }

  async isAdminWallet(wallet_address: string) {
    const url = `/api/admin/check_is_wallet_admin/${wallet_address}`;
    try {
      const { data } = await this._api.get<ISimpleApiResp<{ is_admin_wallet: boolean }>>(url, {
        headers: { 'network-type': NetworkTypes.EVM },
      });

      return data.data.is_admin_wallet;
    } catch (error) {
      stage === 'development' &&
        console.warn(error, '======error isWalletAdmin');
      throw error;
    }
  }
}

export const authAPI = new AuthApi();
