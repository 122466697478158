export const logInGroup = (group: string, ...args: any[]) => {
  console.groupCollapsed(group);
  args.forEach((arg) => console.log(arg));
  console.groupEnd();
};

export const warnInGroup = (group: string, ...args: any[]) => {
  console.groupCollapsed(group);
  args.forEach((arg) => console.warn(arg));
  console.groupEnd();
}

export const errorInGroup = (group: string, ...args: any[]) => {
  console.groupCollapsed(group);
  args.forEach((arg) => console.error(arg));
  console.groupEnd();
}