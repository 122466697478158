import { STAGES, stage } from 'configs/api';

enum Routes {
  MAINNET = 'MAINNET',
  REBALANCE_BOT = 'REBALANCE_BOT',
  TESTNET = 'TESTNET',
  HEDGE_BOT = 'HEDGE_BOT',
  API_ENDPOINTS = 'API_ENDPOINTS',
}

const routes: Record<STAGES, Record<Routes, string>> = {
  development: {
    [Routes.MAINNET]: 'https://admin.retrobridge.dev/apanel',
    [Routes.REBALANCE_BOT]: 'https://admin.retrobridge.dev/apanel/rebalance',
    [Routes.TESTNET]: '/',
    [Routes.HEDGE_BOT]: 'https://admin.retrobridge.dev/apanel/hedge',
    [Routes.API_ENDPOINTS]:
      'https://admin.retrobridge.dev/apanel/api-endpoints',
  },
  staging: {
    [Routes.MAINNET]: 'https://admin.retrobridge.dev/apanel',
    [Routes.REBALANCE_BOT]: 'https://admin.retrobridge.dev/apanel/rebalance',
    [Routes.TESTNET]: 'https://testnet.admin.retrobridge.dev/apanel',
    [Routes.HEDGE_BOT]: 'https://admin.retrobridge.dev/apanel/hedge',
    [Routes.API_ENDPOINTS]:
      'https://admin.retrobridge.dev/apanel/api-endpoints',
  },
  production: {
    [Routes.MAINNET]: 'https://retrobridge.finance/apanel',
    [Routes.REBALANCE_BOT]: 'https://retrobridge.finance/apanel/rebalance',
    [Routes.TESTNET]: 'https://testnet.retrobridge.finance/apanel',
    [Routes.HEDGE_BOT]: 'https://retrobridge.finance/apanel/hedge',
    [Routes.API_ENDPOINTS]: 'https://retrobridge.finance/apanel/api-endpoints',
  },
};

export const ROUTES = routes[stage];
