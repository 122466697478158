import axios, { AxiosInstance } from 'axios';
import { BASE_URL } from 'configs/api';
import {
  BalancerThresholdConfigs,
  BalancerThresholdConfigsBody,
  ICurrency,
  INetwork,
  INetworkWithCurrencies,
  NetworkTypes,
} from 'types/networks';
import { warnInGroup } from 'utils/log';

export class NetworksApi {
  private _api: AxiosInstance;

  constructor() {
    this._api = axios.create({
      baseURL: BASE_URL,
      withCredentials: true,
    });
  }

  async getNetworks(): Promise<INetworkWithCurrencies[]> {
    try {
      const { data } = await this._api.get('/api/admin/networks', {
        headers: { 'network-type': NetworkTypes.EVM },
      });

      return data.data;
    } catch (error) {
      warnInGroup('NetworksApi.getNetworks', error);
    }
    return [];
  }

  async toggleNetwork(
    id: INetworkWithCurrencies['id'],
    enabled: INetworkWithCurrencies['active']
  ) {
    await this._api.put(
      `/api/admin/toggle_network/${id}/${enabled}`,
      {},
      {
        headers: { 'network-type': NetworkTypes.EVM },
      }
    );
  }

  async toggleCurrency(id: ICurrency['id'], enabled: ICurrency['active']) {
    await this._api.put(
      `/api/admin/toggle_currency/${id}/${enabled}`,
      {},
      {
        headers: { 'network-type': NetworkTypes.EVM },
      }
    );
  }

  async setCurrencyLimit(
    currencyId: ICurrency['id'],
    min: ICurrency['min_send'],
    max: ICurrency['max_send']
  ) {
    await this._api.put(
      `/api/admin/change_currency_limits/${currencyId}/${min}/${max}`,
      {},
      {
        headers: { 'network-type': NetworkTypes.EVM },
      }
    );
  }

  async setGasMultiplierCoefficients(coefficients: {
    [key: INetwork['name']]: number;
  }) {
    return this._api.put(
      `/api/admin/set_gas_multiplier_coefficients`,
      coefficients,
      {
        headers: { 'network-type': NetworkTypes.EVM },
      }
    );
  }

  async getExceptionalNetworks(): Promise<INetwork[]> {
    try {
      const { data } = await this._api.get('/api/admin/exceptional_networks', {
        headers: { 'network-type': NetworkTypes.EVM },
      });

      return data.data;
    } catch (error) {
      warnInGroup('NetworksApi.getExceptionalNetworks', error);
    }
    return [];
  }

  async setExceptionalNetworks(coefficients: {
    [key: INetwork['name']]: number;
  }) {
    await this._api.put(
      '/api/admin/set_coefficients_for_exceptional_networks',
      coefficients,
      {
        headers: { 'network-type': NetworkTypes.EVM },
      }
    );
  }

  async getBalancerThresholdConfigs(): Promise<BalancerThresholdConfigs[]> {
    const url = '/api/admin/balancer_thresholds_config';

    try {
      const { data } = await this._api.get<BalancerThresholdConfigs[]>(url, {
        headers: { 'network-type': NetworkTypes.EVM },
      });

      return data;
    } catch (error) {
      warnInGroup('NetworksApi.getBalancerThresholdConfigs', error);
    }
    return [];
  }

  async setBalancerThresholdConfigs(
    type: 'low' | 'high',
    config: BalancerThresholdConfigsBody
  ) {
    const url =
      type === 'low'
        ? '/api/admin/low_balance_config'
        : '/api/admin/high_balance_config';

    await this._api.put<BalancerThresholdConfigs[]>(
      url,
      { config },
      {
        headers: { 'network-type': NetworkTypes.EVM },
      }
    );
  }
}

export const networksApi = new NetworksApi();
