const devUrl = 'https://testnet.backend.retrobridge.dev';
const stagingUrl = 'https://testnet.backend.retrobridge.dev';
const prodUrl = 'https://testnet.backend.retrobridge.io';

export type STAGES = 'development' | 'production' | 'staging';

const stages: Record<STAGES, string> = {
  development: devUrl,
  production: prodUrl,
  staging: stagingUrl,
};

export const stage = (process.env.REACT_APP_STAGE as STAGES) || 'development';

export const BASE_URL = stages[stage];
