import { useCallback, useMemo } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CloseIcon from "assets/icons/close-icon.svg";
import SuccessIcon from "assets/icons/success-icon.svg";

export const useNotify = () => {
  const notify = useCallback(
    (props: {
      meassage: string;
      type: "success" | "error" | "info";
      title?: string;
    }) => {
      toast[props.type](
        <div className={`notification _${props.type}`}>
          <div className="notification-border" />
          <div className="notification-left">
            {props.type === "success" && (
              <img
                style={{ marginRight: "8px" }}
                src={SuccessIcon}
                alt="success"
              />
            )}

            {!!props.title ? (
              <div className="text-container ">
                <p style={{ marginBottom: `10px` }}>{props.title}</p>
                <p style={{ margin: 0 }}>{props.meassage}</p>
              </div>
            ) : (
              <p style={{ margin: 0 }}>{props.meassage}</p>
            )}
          </div>
          <div>
            <img src={CloseIcon} alt="close" className="notification-img" />
          </div>
        </div>,
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          icon: false,
          type: props.type,
          draggable: true,
          closeButton: false,
          progress: undefined,
          theme: "dark",
          style: {
            background: "transparent",
            boxShadow: "none",
          },
          toastId: 'custom',
        }
      );
    },
    []
  );

  return useMemo(
    () => ({
      notify,
    }),
    [notify]
  );
};
