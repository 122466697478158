import { SVGAttributes } from "react";

export function Logo(props: SVGAttributes<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="138"
      height="14"
      viewBox="0 0 138 14"
      fill="none"
      {...props}
    >
      <path
        d="M2.91779 13.4035H0.865967V1.68565H5.33831C7.99927 1.68565 9.55417 3.03216 9.55417 5.34046C9.55417 6.91139 8.81679 8.03348 7.43822 8.59453L9.66638 13.4035H7.42219L5.43449 9.02733H2.91779V13.4035ZM2.91779 3.51305V7.21596H5.33831C6.63673 7.21596 7.40616 6.52667 7.40616 5.34046C7.40616 4.17028 6.63673 3.51305 5.33831 3.51305H2.91779Z"
        fill="white"
      />
      <path
        d="M20.0846 13.4035H12.807V1.68565H20.0846V3.57717H14.8588V6.59079H19.5395V8.40217H14.8588V11.512H20.0846V13.4035Z"
        fill="white"
      />
      <path
        d="M22.4854 3.57717V1.68565H31.398V3.57717H27.9676V13.4035H25.9158V3.57717H22.4854Z"
        fill="white"
      />
      <path
        d="M36.2205 13.4035H34.1687V1.68565H38.641C41.302 1.68565 42.8569 3.03216 42.8569 5.34046C42.8569 6.91139 42.1195 8.03348 40.7409 8.59453L42.9691 13.4035H40.7249L38.7372 9.02733H36.2205V13.4035ZM36.2205 3.51305V7.21596H38.641C39.9394 7.21596 40.7089 6.52667 40.7089 5.34046C40.7089 4.17028 39.9394 3.51305 38.641 3.51305H36.2205Z"
        fill="white"
      />
      <path
        d="M73.7237 13.4035V1.68565H78.3884C80.6967 1.68565 82.1073 2.88789 82.1073 4.84353C82.1073 6.14195 81.5302 7.07169 80.4242 7.53655C81.6585 7.9373 82.2836 8.86704 82.2836 10.2296C82.2836 12.2333 80.889 13.4035 78.4685 13.4035H73.7237ZM78.2121 3.49702H75.7755V6.68697H78.2601C79.3822 6.68697 80.0074 6.09386 80.0074 5.05192C80.0074 4.04204 79.3662 3.49702 78.2121 3.49702ZM78.3884 8.4182H75.7755V11.5921H78.3884C79.5425 11.5921 80.1837 11.0311 80.1837 9.9731C80.1837 8.97924 79.5265 8.4182 78.3884 8.4182Z"
        fill="white"
      />
      <path
        d="M87.4191 13.4035H85.3673V1.68565H89.8396C92.5006 1.68565 94.0555 3.03216 94.0555 5.34046C94.0555 6.91139 93.3181 8.03348 91.9395 8.59453L94.1677 13.4035H91.9235L89.9358 9.02733H87.4191V13.4035ZM87.4191 3.51305V7.21596H89.8396C91.138 7.21596 91.9075 6.52667 91.9075 5.34046C91.9075 4.17028 91.138 3.51305 89.8396 3.51305H87.4191Z"
        fill="white"
      />
      <path
        d="M99.3601 1.68565V13.4035H97.3083V1.68565H99.3601Z"
        fill="white"
      />
      <path
        d="M107.376 13.4035H103.16V1.68565H107.263C110.726 1.68565 113.114 4.0741 113.114 7.56861C113.114 11.015 110.774 13.4035 107.376 13.4035ZM107.071 3.57717H105.212V11.512H107.183C109.524 11.512 110.95 10.0052 110.95 7.56861C110.95 5.08398 109.492 3.57717 107.071 3.57717Z"
        fill="white"
      />
      <path
        d="M121.357 3.41687C119.033 3.41687 117.703 5.10001 117.703 7.63273C117.703 10.2456 119.209 11.6723 121.374 11.6723C123.409 11.6723 124.644 10.5502 124.644 8.67468V8.62659H121.101V6.81521H126.551V13.4035H124.82L124.692 12.057C124.051 12.9547 122.72 13.5798 121.133 13.5798C117.847 13.5798 115.555 11.1914 115.555 7.58464C115.555 4.02601 117.879 1.49329 121.406 1.49329C124.083 1.49329 126.15 3.04819 126.487 5.43664H124.323C123.954 4.05807 122.768 3.41687 121.357 3.41687Z"
        fill="white"
      />
      <path
        d="M137.732 13.4035H130.454V1.68565H137.732V3.57717H132.506V6.59079H137.187V8.40217H132.506V11.512H137.732V13.4035Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M58.5346 2.5763C52.2831 2.5763 47.2152 7.42381 47.2152 13.4035H45.3672C45.3672 6.44753 51.2624 0.808594 58.5346 0.808594C65.8068 0.808594 71.702 6.44753 71.702 13.4035H69.8539C69.8539 7.42381 64.7861 2.5763 58.5346 2.5763Z"
        fill="url(#paint0_linear_6529_9610)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_6529_9610"
          x1="45.3672"
          y1="10.541"
          x2="71.702"
          y2="10.541"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8F7CFF" />
          <stop offset="1" stopColor="#E5C4FF" />
        </linearGradient>
      </defs>
    </svg>
  );
}