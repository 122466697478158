import { DropdownIcon } from 'assets/icons/DropdownIcon';
import clsx from 'clsx';
import { BASE_URL } from 'configs/api';
import { useImgPreloader } from 'hooks/useImagePreload';
import { useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { INetwork } from 'types/networks';
import { INetworkAssetsValuesForm } from '.';

interface NetworkNetworkAssetValueItemProps {
  network: INetwork;
  i: number;
}

export function NetworkAssetValueItem({
  network,
  i,
}: NetworkNetworkAssetValueItemProps) {
  const [isExpanded, setIsExpanded] = useState(true);
  const form = useFormContext<INetworkAssetsValuesForm>();

  const { imgsPreloaded } = useImgPreloader([
    BASE_URL + network.network_image_url,
  ]);

  const networkActive = useMemo(() => {
    return network.active;
  }, [network.active]);

  return (
    <div className="inline-flex flex-col">
      <div
        className={clsx(
          'flex items-center text-sm leading-[1.0625rem',
          !networkActive && 'disabled'
        )}
      >
        <div className="relative flex justify-center items-center w-7 min-w-7 h-7 bg-white rounded-full mr-3">
          <div
            className={clsx(
              'absolute inset-0 bg-[#A39DC7] rounded-full',
              networkActive ? 'opacity-0' : 'opacity-100'
            )}
          />
          <img
            src={BASE_URL + network.network_image_url}
            className={clsx(
              'w-[1.3rem] min-w-[1.3rem] h-[1.3rem] transition-all duration-200',
              !networkActive && 'mix-blend-luminosity',
              imgsPreloaded ? 'opacity-100' : 'opacity-0'
            )}
            alt=""
          />
        </div>
        <div
          className={clsx(
            'mr-auto transition-colors duration-200',
            networkActive ? 'text-white' : 'text-[#A39DC7]'
          )}
        >
          {network.name}
        </div>
        <div className="relative flex items-center ml-6 pr-6">
          <button
            type="button"
            className="absolute right-0 bg-transparent border-none"
            onClick={() => setIsExpanded(!isExpanded)}
          >
            <DropdownIcon className={clsx(isExpanded && 'rotate-180')} />
          </button>
        </div>
      </div>
      {isExpanded && (
        <div className="flex flex-col gap-2 mt-[0.8125rem] ml-[2.5rem]">
          <div className="flex items-center gap-3 text-sm leading-[1.0625rem]">
            <div
              className={clsx(
                'flex justify-center items-center w-5 min-w-5 h-5 rounded-full bg-white transition-opacity duration-200',
                imgsPreloaded ? 'opacity-100' : 'opacity-0'
              )}
            >
              <img
                src={BASE_URL + network?.network_image_url}
                className="w-4 min-w-4 h-4"
                alt=""
              />
            </div>
            <div
              className={clsx(
                'mr-auto transition-colors duration-200',
                !networkActive ? 'text-[#A39DC7]' : 'text-white'
              )}
            >
              {network.token_symbol}
            </div>
            <div className="ml-auto w-min">
              <Controller
                name={`networks.${i}.coefficient`}
                control={form.control}
                render={({ field }) => (
                  <input
                    type="text"
                    className="h-[2.5625rem] w-[5.5rem] max-w-full px-5 bg-white bg-opacity-10 rounded-lg text-sm font-medium text-white outline-none"
                    value={field.value}
                    onChange={(e) => {
                      let val = e.target.value;
                      if (!/^[0-9]*([.|,]([0-9]+)?)?$/.test(val)) return;
                      field.onChange(val);
                    }}
                    onBlur={(e) => {
                      let val = e.target.value;
                      val = val.replace(',', '.');
                      if (val.startsWith('.')) val = '0' + val;
                      if (val.endsWith('.')) val = val.slice(0, -1);
                      field.onChange(val);
                      field.onBlur();
                    }}
                  />
                )}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
