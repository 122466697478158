import { connectorsForWallets } from '@rainbow-me/rainbowkit';
import {
  bitgetWallet,
  coin98Wallet,
  coinbaseWallet,
  foxWallet,
  imTokenWallet,
  metaMaskWallet,
  okxWallet,
  phantomWallet,
  rabbyWallet,
  rainbowWallet,
  safepalWallet,
  tokenPocketWallet,
  trustWallet,
  walletConnectWallet,
  zerionWallet,
} from '@rainbow-me/rainbowkit/wallets';

import { configureChains, createConfig, mainnet } from 'wagmi';
import { publicProvider } from 'wagmi/providers/public';

export type WagmiConnectors = {
  name: string;
};

export const { chains, publicClient, webSocketPublicClient } = configureChains(
  [mainnet], // Must have at least one chain
  [publicProvider()],
);

export const WALLET_CONNECT_PROJECT_ID = 'ef1ab3184c753ffb2b5c10f01449260c';

export const wallets = [
  metaMaskWallet({
    projectId: WALLET_CONNECT_PROJECT_ID,
    chains: [],
    shimDisconnect: true,
  }),
  trustWallet({
    projectId: WALLET_CONNECT_PROJECT_ID,
    chains: [],
    shimDisconnect: true,
  }),
  rabbyWallet({ chains: [] }),
  phantomWallet({
    chains: [],
    shimDisconnect: true,
  }),
  coinbaseWallet({ appName: 'RetroBridge', chains: [] }),
  rainbowWallet({ projectId: WALLET_CONNECT_PROJECT_ID, chains: [] }),

  bitgetWallet({
    name: 'Bitget',
    chains: [],
    projectId: WALLET_CONNECT_PROJECT_ID,
    shimDisconnect: true,
    getProvider: () => window.bitkeep.ethereum,
  }),
  okxWallet({
    name: 'OKX Wallet',
    chains: [],
    projectId: WALLET_CONNECT_PROJECT_ID,
    shimDisconnect: true,
    getProvider: () => window.okexchain,
  }),
  coin98Wallet({
    chains: [],
    projectId: WALLET_CONNECT_PROJECT_ID,
    shimDisconnect: true,
  }),
  imTokenWallet({ chains, projectId: WALLET_CONNECT_PROJECT_ID }),
  safepalWallet({
    chains: [],
    projectId: WALLET_CONNECT_PROJECT_ID,
    shimDisconnect: true,
  }),
  zerionWallet({
    name: 'Zerion',
    chains: [],
    projectId: WALLET_CONNECT_PROJECT_ID,
    shimDisconnect: true,
  }),
  foxWallet({
    chains: [],
    projectId: WALLET_CONNECT_PROJECT_ID,
    shimDisconnect: true,
  }),
  tokenPocketWallet({
    chains: [],
    projectId: WALLET_CONNECT_PROJECT_ID,
    shimDisconnect: true,
  }),
  walletConnectWallet({
    chains: [],
    projectId: WALLET_CONNECT_PROJECT_ID,
    version: '2',
    options: {
      projectId: WALLET_CONNECT_PROJECT_ID,
      qrModalOptions: {
        themeVariables: {
          '--wcm-z-index': '9999',
        },
      },
    },
  }),
];

const connectors = connectorsForWallets([
  {
    groupName: 'Recommended',
    wallets,
  },
]);

export const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
  webSocketPublicClient,
});
