export enum NetworkTypes {
  EVM = 'EVM',
  ZK_SYNC_ERA = 'ZK_SYNC_ERA',
  STARKNET = 'STARKNET',
}

export interface INetwork {
  id: string;
  name: string;
  chainId: string;
  network_image_url: string;
  blockscan_url: string;
  token_symbol: string;
  token_decimals: number;
  network_type: NetworkTypes;
  category: string;
  active: boolean;
  nativeTransferGas: string;
  gasPrice: string;
  multiplier: number;
  coefficient: number;
}

export interface INetworkWithCurrencies extends INetwork {
  currencies: ICurrency[];
}

export interface ICurrency {
  id: string;
  symbol: string;
  contract: {
    id: string;
    address: string;
    network: INetwork;
    type: string;
  };
  decimals: number;
  image_url: string;
  min_send: number;
  max_send: number;
  active: boolean;
}

export interface BalancerThresholdConfigs extends INetwork {
  currencies: (ICurrency & {
    min_balance: number;
    high_balance: number;
  })[];
}

export interface BalancerThresholdConfigsBody {
  [key: ICurrency['symbol']]: {
    [key: INetwork['name']]: number;
  };
}
