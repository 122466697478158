import './styles.css';
import power from 'assets/icons/power.svg';
import powerWhite from 'assets/icons/power-white.svg';
import Jazzicon, { jsNumberForAddress } from 'react-jazzicon';
import { formatAddress } from 'utils/wallet';
import { useAccount, useDisconnect } from 'wagmi';
import { ReactNode, useState } from 'react';
import {
  PopoverContent,
  PopoverPortal,
  PopoverTrigger,
  Root,
} from '@radix-ui/react-popover';

export const WalletDisconnectDropdown = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { address } = useAccount();
  const { disconnect } = useDisconnect();

  const handleClick = () => {
    disconnect();
    setIsOpen(false);
  };

  const stopPropagation = (e: React.MouseEvent<Element, MouseEvent>) => {
    e.stopPropagation();
  };

  return (
    <Root open={isOpen} onOpenChange={setIsOpen}>
      <PopoverTrigger asChild>{children}</PopoverTrigger>
      <PopoverPortal>
        <PopoverContent align='end' asChild>
          <div className="walletDisconnectModal" onClick={stopPropagation}>
            <div
              className="coinInfoContainer "
              style={{ paddingLeft: '20px', paddingRight: '20px' }}
            >
              <div className="flex items-center walletDisconnectModal__account-wrapper">
                <div className="walletDisconnectModalImg">
                  <Jazzicon
                    diameter={28}
                    seed={address && jsNumberForAddress(address)}
                  />
                </div>

                <div className="flex items-start flex-col">
                  <div className="pl-1 walletDisconnectModalTopText  me-2">
                    <span>Connected account</span>
                  </div>
                  <div className="pl-1 walletDisconnectModalBottomText me-2">
                    <span>{address && formatAddress(address)}</span>
                  </div>
                </div>
              </div>
            </div>

            <div style={{ paddingLeft: '20px', paddingRight: '20px' }}>
              <div className="walletDisconnectModalDivider me-auto  " />
            </div>
            <div
              onClick={handleClick}
              className="walletDisconnectModalDangerDiv"
            >
              <img
                src={power}
                alt=""
                className="powerIconSize power-icon-display"
              />
              <img
                src={powerWhite}
                alt=""
                className="powerIconSize power-icon-white-display"
              />
              <span className="walletDisconnectModalDangerText">
                Disconnect
              </span>
            </div>
          </div>
        </PopoverContent>
      </PopoverPortal>
    </Root>
  );
};
