import { Loader } from 'components/Loader';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { authAPI } from 'services/auth';
import { useAccount } from 'wagmi';

export const PrivateRoute = ({ children }: { children: JSX.Element }) => {
  const [isLoading, setIsLoading] = useState(true);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { address } = useAccount();

  const oldEthAddressRef = useRef<`0x${string}` | null>(null);

  const checkIsAuthWallet = async (address: string) => {
    try {
      await authAPI.checkWallet(address);
      return true;
    } catch (error) {
      return false;
    }
  };

  useEffect(() => {
    if (!address) {
      navigate('/sign-in');
      return;
    }

    if (address === oldEthAddressRef.current) {
      setIsLoading(false);
      return;
    }

    setIsLoading(true);
    checkIsAuthWallet(address)
      .then((isAuth) => {
        setIsLoading(false);
        if (pathname === '/sign-in' && isAuth) {
          navigate('/');
        }
        if (!isAuth) {
          navigate('/sign-in');
        }
      })
      .catch(() => {
        setIsLoading(false);
        navigate('/sign-in');
      });
  }, [pathname, address]);

  if (isLoading) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  return children;
};
