import { SVGAttributes } from "react";

export function DropdownIcon(props: SVGAttributes<SVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        d="M8 10.6667L4.5359 6.66667L11.4641 6.66667L8 10.6667Z"
        fill="white"
      />
    </svg>
  );
}