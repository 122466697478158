import {
  DialogContent,
  DialogOverlay,
  DialogPortal,
  DialogTrigger,
  Root,
} from '@radix-ui/react-dialog';
import { WalletButton } from '@rainbow-me/rainbowkit';
import CloseIcon from 'assets/icons/close-icon.svg';
import clsx from 'clsx';
import { ReactNode, useEffect, useState } from 'react';
import { useAccount } from 'wagmi';
import { wallets } from '../../configs/wagmi';
import './styles.css';

function ConnectModal({
  children,
  variant = 'DEFAULT',
}: {
  children?: ReactNode;
  variant?: 'DEFAULT' | 'small';
}) {
  const [openModal, setOpenModal] = useState(false);
  const { isConnected } = useAccount();

  useEffect(() => {
    if (isConnected) {
      setOpenModal(false);
    }
  }, [isConnected]);

  return (
    <Root open={openModal} onOpenChange={(val) => setOpenModal(val)}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogPortal>
        <DialogOverlay className="fixed inset-0 bg-black bg-opacity-50 z-[10]" />
        <DialogContent className="fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-transparent connectModalModalBody z-[20]">
          <div
            onClick={(e) => e.stopPropagation()}
            className={clsx(
              'defaultRadius gradient-border-mask-connect-wallet connectFormContainer',
              variant === 'small' && 'small'
            )}
          >
            <div
              className="connectModalCloseBtn cursor-pointer"
              onClick={() => setOpenModal(false)}
            >
              <img
                className="notification-img"
                style={{ width: 20 }}
                alt=""
                src={CloseIcon}
              />
            </div>
            <div className=" flex-column d-flex ">
              <div className="d-flex connectModalTitle justify-content-center align-items-center  ">
                Select your wallet to login
              </div>
              <div className="d-grid gap-1. activeWalletsGrid">
                {wallets.map((item, i) => {
                  return (
                    <WalletButton.Custom key={i} wallet={item.id}>
                      {({ connect, connector }) => {
                        return (
                          <div
                            className={`connectModalItem d-flex  align-items-center activeWalletsGridItem${i} `}
                            onClick={
                              connector.id === 'walletConnect'
                                ? connector.showWalletConnectModal
                                : connect
                            }
                          >
                            <div className="center connectModalIconContainer">
                              <img
                                src={require(`assets/images/wallets/${item.id}.png`)}
                                style={{ height: '24px', width: '24px' }}
                                alt=""
                              />
                            </div>
                            <span className="connectNetworkSpan">
                              {connector.name}
                            </span>
                          </div>
                        );
                      }}
                    </WalletButton.Custom>
                  );
                })}
              </div>
            </div>
          </div>
        </DialogContent>
      </DialogPortal>
    </Root>
  );
}

export default ConnectModal;
