import {
  DialogClose,
  DialogContent,
  DialogPortal,
  DialogTrigger,
  Root,
} from '@radix-ui/react-dialog';
import { Close } from 'assets/icons/Close';
import { Logo } from 'assets/icons/Logo';
import { Menu } from 'assets/icons/Menu';
import { WalletMenu } from 'components/WalletMenu';
import { ROUTES } from 'constants.routes';
import { AnimatePresence, motion } from 'framer-motion';
import { useWindowResize } from 'hooks/useWindowResize';
import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

export function HeaderMobileMenu() {
  const [isOpen, setIsOpen] = useState(false);

  const { width } = useWindowResize();

  useEffect(() => {
    if (width > 640) {
      setIsOpen(false);
    }
  }, [width]);

  return (
    <Root open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger className="flex justify-center items-center ml-4 sm:hidden">
        <Menu />
      </DialogTrigger>
      <AnimatePresence>
        {isOpen && (
          <DialogPortal forceMount>
            <DialogContent asChild>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="fixed inset-0 bg-[#030016]"
              >
                <div className="flex items-center pl-2 pr-5 py-[1.6875rem] md:px-10 md:py-[2.1875rem]">
                  <div className="px-3 py-3.5 mr-auto">
                    <Logo />
                  </div>
                  <WalletMenu />
                  <DialogClose className="ml-4">
                    <Close />
                  </DialogClose>
                </div>
                <nav className="flex flex-col items-center gap-[3.125rem] mt-[2.0625rem]">
                  <NavLink
                    to={ROUTES.API_ENDPOINTS}
                    end
                    className="text-white text-sm font-medium header-nav-item"
                  >
                    API EndPoints
                  </NavLink>
                  <NavLink
                    to={ROUTES.MAINNET}
                    end
                    className="text-white text-lg font-bold header-nav-item"
                  >
                    Mainnet
                  </NavLink>
                  <NavLink
                    to={ROUTES.TESTNET}
                    end
                    className="text-white text-lg font-bold header-nav-item"
                  >
                    Testnet
                  </NavLink>
                  <NavLink
                    to={ROUTES.HEDGE_BOT}
                    end
                    className="text-white text-lg font-bold header-nav-item"
                  >
                    Hedge bot
                  </NavLink>
                  <NavLink
                    to={ROUTES.REBALANCE_BOT}
                    end
                    className="text-white text-lg font-bold header-nav-item"
                  >
                    Rebalance bot
                  </NavLink>
                </nav>
              </motion.div>
            </DialogContent>
          </DialogPortal>
        )}
      </AnimatePresence>
    </Root>
  );
}
