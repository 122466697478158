import { DropdownIcon } from 'assets/icons/DropdownIcon';
import clsx from 'clsx';
import { Button } from 'components/Button';
import { useWindowResize } from 'hooks/useWindowResize';
import { useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { networksApi } from 'services/networks';
import useSWR from 'swr';
import { ICurrency, INetworkWithCurrencies } from 'types/networks';
import { NetworkStatusItem } from './NetworkStatusItem';
import { useNotify } from 'hooks/useToast';
import { AxiosError } from 'axios';
import { chunk } from 'lodash';

export interface INetworkStatusForm {
  networks: INetworkWithCurrencies[];
}

export function NetworkStatus() {
  const { width } = useWindowResize();
  const [isExpanded, setIsExpanded] = useState(false);
  const { data, mutate } = useSWR('networks', () => networksApi.getNetworks(), {
    revalidateOnFocus: false,
  });
  const { notify } = useNotify();

  const form = useForm<INetworkStatusForm>({
    values: {
      networks: data || [],
    },
  });

  const handleSubmit = async () => {
    try {
      const dirtyFields = form.formState.dirtyFields.networks;
      const formValues = form.getValues();

      // Update every modified network
      for (const key in dirtyFields) {
        const dirtyField = dirtyFields[
          key as keyof typeof dirtyFields
        ] as Partial<INetworkWithCurrencies>;

        const network = formValues.networks[
          key as keyof typeof formValues.networks
        ] as INetworkWithCurrencies;

        // if currencies are modified, update them first
        if (dirtyField.currencies && dirtyField.currencies.length > 0) {
          // Update every modified currency for the network
          for (const cKey in dirtyField.currencies) {
            const currency = network.currencies[
              cKey as keyof typeof network.currencies
            ] as ICurrency;

            await networksApi.toggleCurrency(currency.id, currency.active);
          }
        }

        await networksApi.toggleNetwork(network.id, network.active);
      }

      await mutate();
      notify({
        meassage: 'All changes saved!',
        type: 'success',
      });
    } catch (error) {
      if (error instanceof AxiosError) {
        notify({
          meassage: error.response?.data.message || error.message,
          type: 'error',
        });
      } else if (error instanceof Error) {
        notify({
          meassage: error.message,
          type: 'error',
        });
      }
    }
  };

  const [virtualGrid, cols] = useMemo(() => {
    let cols = 4;

    if (width < 768) {
      cols = 1;
    } else if (width < 1024) {
      cols = 2;
    } else if (width < 1280) {
      cols = 3;
    }

    return [
      data ? chunk(data, Math.ceil(data.length / cols)) : [],
      data ? Math.ceil(data.length / cols) : 0,
    ];
  }, [data, width]);

  return (
    <form
      onSubmit={form.handleSubmit(handleSubmit)}
      className="mb-[3.75rem] border-[#B5B2C9] border-opacity-30 md:mb-[3.3125rem] md:pb-[3.3125rem] md:border-b"
    >
      <div className="flex justify-between items-center pb-10">
        <h2 className="font-medium text-lg leading-5 md:text-2xl">
          Network status
        </h2>
        <Button
          type="submit"
          disabled={!form.formState.isDirty || form.formState.isSubmitting}
          extraClass="hidden md:block"
        >
          {form.formState.isSubmitting ? 'Saving...' : 'Save changes'}
        </Button>
      </div>
      <FormProvider {...form}>
        <div className="grid grid-cols-1 auto-rows-min md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-8 gap-y-6">
          {data &&
            virtualGrid.map((chunk, k) => (
              <div key={`chunk-${k}`} className="flex flex-col gap-x-8 gap-y-6">
                {chunk
                  .slice(0, width < 768 && !isExpanded ? 5 : data.length)
                  .map((network, i) => (
                    <NetworkStatusItem
                      network={network}
                      i={i + k * cols}
                      key={i + k * cols}
                    />
                  ))}
              </div>
            ))}
        </div>
      </FormProvider>
      <button
        type="button"
        onClick={() => setIsExpanded(!isExpanded)}
        className="flex items-center gap-2 mx-auto mt-8 text-sm leading-5 font-bold md:hidden"
      >
        {isExpanded ? 'Show less' : 'Show all'}
        <DropdownIcon className={clsx(isExpanded && 'rotate-180')} />
      </button>
      <Button
        disabled={!form.formState.isDirty || form.formState.isSubmitting}
        type="submit"
        extraClass="block mx-auto mt-6 md:hidden"
      >
        {form.formState.isSubmitting ? 'Saving...' : 'Save changes'}
      </Button>
    </form>
  );
}
